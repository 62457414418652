<template>
    <div class="detail">
	    
	    <div class="content">
		    <el-form ref="form">
			    
			    <el-form-item label="选择平台" class="platform-choose">
				    <el-select v-model="driverData.platform" placeholder="平台" style="width:170px">
					    <el-option label="百家号" value="baijiahao"></el-option>
              <el-option label="某音" value="douyin"></el-option>
              <el-option label="视频号" value="shipinhao"></el-option>
              <el-option label="官网" value="officialWeb"></el-option>
              <el-option label="小红书" value="redBook"></el-option>
					    <el-option label="哔哩哔哩" value="bilibili"></el-option>
					    <el-option label="网易号" value="yangyi"></el-option>
					    <el-option label="头条号" value="toutiaohao"></el-option>
					    <el-option label="大鱼号" value="dayuhao"></el-option>
				    </el-select>
			    </el-form-item>
			    
			    
			    <div class="bind-item" v-if="driverData.platform === 'baijiahao'">
				    <h1 style="margin-top:5rem">百家号</h1>
				    <el-form ref="form"  style="margin-right: 1rem; margin-top:3rem">
					    <el-form-item >
						    <el-form-item label="账户">
							    <el-input v-model="driverData.account" placeholder="账户"></el-input>
						    </el-form-item>
						    <el-form-item label="密码">
							    <el-input v-model="driverData.password" placeholder="密码"></el-input>
						    </el-form-item>
						    <el-form-item label="备注">
							    <el-input v-model="driverData.remark" placeholder="选填"></el-input>
						    </el-form-item>
					    
					    </el-form-item>
				    </el-form>
			    </div>


          <div class="bind-item" v-if="driverData.platform === 'douyin'">
            <h1>某音</h1>
            <el-form ref="form"  style="margin-right: 1rem; margin-top:3rem">
              <el-form-item >
                <el-form-item label="手机号">
                  <el-input v-model="driverData.account"></el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item >
                <el-form-item label="Cookie">
                  <el-input v-model="driverData.cookie" type="textarea" rows="10" placeholder="没有可不填, 点击底部登录完后会自动保存"></el-input>
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>

          <div class="bind-item" v-if="driverData.platform === 'shipinhao'">
            <h1>视频号</h1>
            <el-form ref="form"  style="margin-right: 1rem; margin-top:3rem">
              <el-form-item >
                <el-form-item label="视屏号: 备注">
                  <el-input v-model="driverData.account"></el-input>
                </el-form-item>
              </el-form-item>
              <el-form-item >
                <el-form-item label="Cookie">
                  <el-input v-model="driverData.cookie" type="textarea" rows="10" placeholder="没有可不填, 点击底部登录完后会自动保存"></el-input>
                </el-form-item>
              </el-form-item>
            </el-form>
          </div>

			    <div class="bind-item" v-if="driverData.platform === 'redBook'">
				    <h1>小红书</h1>
				    <el-form ref="form"  style="margin-right: 1rem; margin-top:3rem">
					    <el-form-item >
						    <el-form-item label="账户名">
							    <el-input v-model="driverData.account"></el-input>
						    </el-form-item>
					    </el-form-item>
					    <el-form-item >
						    <el-form-item label="apiKey">
							    <el-input v-model="driverData.apiKey"></el-input>
						    </el-form-item>
					    </el-form-item>
				    </el-form>
			    </div>
			    
			    
			    <div class="bind-item" v-if="driverData.platform === 'officialWeb'" style="margin-top:3rem">
				    <h1>官网</h1>
				    <el-form ref="form"  style="margin-right: 1rem; margin-top:3rem">
					    <el-form-item >
						    <el-form-item label="域名">
							    <el-input v-model="driverData.account" placeholder="官网域名"></el-input>
						    </el-form-item>
					    </el-form-item>
					    <el-form-item >
						    <el-form-item label="key">
							    <el-input v-model="driverData.apiKey" placeholder="同步文章需要的key, 选填"></el-input>
						    </el-form-item>
					    </el-form-item>
					    <el-form-item >
						    <el-form-item label="备注">
							    <el-input v-model="driverData.remark" placeholder="选填"></el-input>
						    </el-form-item>
					    </el-form-item>
				    </el-form>
			    </div>

          <div class="bind-item" style="margin-top:3rem">
            <el-form ref="form"  style="margin-right: 1rem; margin-top:3rem">
              <el-form-item >
                <el-form-item label="账户分组: " label-width="100px">

                  <el-checkbox-group v-model="driverData.tagList" style="margin-left:20px">
                    <el-checkbox v-for="tag in allTagList" :label="tag" :key="tag">{{tag}}</el-checkbox>

                    <i class="el-icon-plus" style="font-size: 14px; color: #480000; cursor: pointer;font-weight: 900; margin-left:30px;border: solid 2px;border-radius:3px" @click="addTag"></i>
                  </el-checkbox-group>
                </el-form-item>

              </el-form-item>
            </el-form>

          </div>


          <div class="bind-sure" >
				    <el-button v-loading="bindLoading" type="primary" size="medium" @click="isEdit ? editAccount() : addAccount()">绑定账户</el-button>
			    </div>
		    </el-form>
	    </div>
	    
    </div>
</template>

<script>

import {isPcClient} from "@/utils/utils";

export default {
    data() {
      return {
        isEdit:false,
        bindLoading: false,
        driverData: {
          platform: 'baijiahao',
          account: '',
          password:'',
          remark:'',

          apiKey: '',
          tagList:['默认分组']
        },

        allTagList:['默认分组']
      };
    },

	mounted() {
			//如果存在query的id， 则加载数据
    this.init()
    this.getAllTag()
	},
	watch: {
		platform(to, from) {
			console.log(from, to)
		}
	},
	methods: {
			init() {
				if (this.$route.query.id) {
					console.log(this.$route.query.id)
					this.getDetail(this.$route.query.id).then(res => {
						this.driverData = res;
            if (typeof this.driverData.tagList !== 'object') {
              this.driverData.tagList = ['默认分组']
            }
						this.isEdit = true
					})
				}
			},
	    addAccount() {
		    // 添加驱动
				this.$http.post('/client/driver/addDriver', this.driverData).then(resDriver => {
          if (!isPcClient()) {
            this.$message.error('请在PC客户端使用此功能')
            return
          }

          this.bindLoading = true
          this.$message.info('请在弹出页面中, 完成登录过程')
          pywebview.api.dispatchLogin(this.driverData).then(res => {
            if (!res) {
              this.$message.error('这条记录无法完成登录, 请检查信息填写是否正确')
              return
            }
            this.$http.post('/client/driver/newCookieValid', {id: resDriver.data._id, cookie: res}).then(res => {
              this.$router.push('/driver/driver').then(() => {
                this.$route.matched.find(route => route.path === '/driver/driver').instances.default.$refs.normalTable.getList()
              })
              this.$message.success('绑定成功');
            })
          }).finally(() => {
            this.bindLoading = false
          })
		    });
	    },
		editAccount() {
			// 添加驱动
			let postData = this.driverData
			postData.id = this.$route.query.id
			this.$http.post('/client/driver/editDriver', this.driverData).then(res => {
        this.$message.success('更新成功');
        this.$router.push('/driver/driver').then(() => {
          this.$route.matched.find(route => route.path === '/driver/driver').instances.default.$refs.normalTable.getList()
        })
			});
		},
		getDetail(id) {
			return this.$http.post('/client/driver/getDriverList', {id:id, pageNum:0, pageSize:1}).then(res => {
        return res.data.list[0];
			});
		},
    getAllTag() {
      return this.$http.post('/client/driver/getAllTag', {}).then(res => {
        this.allTagList = res.data
        if (this.allTagList.indexOf('默认分组') === -1) {
          this.allTagList.push('默认分组')
        }
      })
    },
    addTag() {
      //弹窗输入文字
      this.$prompt('请输入标签名称', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(({ value }) => {
        this.allTagList.push(value)
        this.driverData.tagList.push(value)
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    resetForm() {
      this.isEdit = false
      this.driverData = {
        platform: 'baijiahao',
        account: '',
        password:'',
        remark:'',
        apiKey: '',
        tagList:['默认分组']
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.detail {
	padding: 2rem;
	border: solid 1px #ebeef5;
	border-radius: 10px;
	margin: 1.5rem 0 0 1rem;
	.content {
		max-width: 600px;
		margin:0 auto;
		.platform-choose {
			display: flex;
			justify-content: flex-start;
			margin-top: 0;
		}
		
		.bind-sure {
			display: flex;
			justify-content: center;
		}
		
		::v-deep .el-input--mini .el-input__inner {
			height: 28px;
			line-height: 28px;
			text-align: center;
		}
	}
}
h1 {
	text-align: center;
}

</style>
